@import '~@mdi/font/scss/_variables.scss';

%base-border {
  border-radius: $border-radius; }

%btn-no-style {
  background: none;
  border: none;
  transition: all .25s ease-in-out;

  &:active, &:focus {
    outline: none; } }

%base-btn {
  background: none;
  outline: none !important;
  border: none;
  cursor: pointer;
  @extend %base-border; }

%avatar {
  border-radius: 100%;
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

%color-primary {
  color: var(--primary); }

%background-primary {
  background: var(--primary); }

%fvc {
  display: flex;
  align-items: center;
  flex-direction: row; }

%fac {
  display: flex;
  align-items: center;
  justify-content: center; }


@mixin size($size) {
  height: $size;
  width: $size; }

@mixin mdi($icon-name) {
  content: #{'"\\' + map-get($mdi-icons, $icon-name)  + '"'};
  font-family: $mdi-font-name; }

$commonSizes: 25 30 35 40 45 50 60 70 80;

@each $size in $commonSizes {
  .sq-#{$size} {
    @include size(#{$size+"px"}); } }
